import React from "react"
/* import { graphql, Link } from "gatsby" */
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Procuct = ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="IoTソリューション・製品"
      pagedesc="IoTソリューション及び取扱製品情報"
      pagepath={location.pathname}
    />

    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.iot_solution.childImageSharp.fluid}
          alt="IoT関連ソリューションを提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          IoT <span>Solution</span>
        </h2>
        <br />
        <br />
        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_solution_pct01.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>IoT関連PoCの支援</h3>
              <p>
                当社は、IoTプラットフォーム、エッジ、デバイスの各レイヤにおいて、
                豊富な実績があり、PoCの企画をはじめ、設計・構築も含めた全てのプロセス
                における支援をトータルで行うことができます。
                また、ご要望に応じて、部品からのデバイスのプロトタイプ制作はもちろん、
                コスト等に優れた海外製品の調達も可能です。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_solution_pct02.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>デバイス制作の支援</h3>
              <p>
                オフィス、工場、農業など、お客様の様々な現場のご要望に応じてお手伝いいたします。 
                各種センサー、カメラ、警報装置等を使って、現場の状況把握や問題解決を行う際に出てくる
                「これができればいいのに…」 や「もっと安くできたらいいのに…」などと言ったご要望等をお客様とご一緒に対処いたします。
                また、世界のハードウェア製作センターである深センのエコシステムを活かしたOEM生産や製品化をお手伝いします。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_solution_pct03.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>プロジェクト支援</h3>
              <p>
                開発プロセスの整備、機能・非機能の要件定義、外部設計等、
                プロジェクトのマネージメント支援を行います。
                ご要望に応じて、ウォータフォール以外に、アジャイル開発におけるチーム形成、
                コミュニケーションツール・プロジェクト管理ツールの活用、
                DevOpsの推進等、様々な角度からのコンサルティングを行うことができます。  
              </p>
          </div>
        </div>
      </div>
    </section>

    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.iot_product.childImageSharp.fluid}
          alt="IoTゲートウェイや各種デバイスを提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          IoT <span>Product</span>
        </h2>
        <br />
        <br />
        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_product_pct01.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>ビーコンデバイス</h3>
              <p>
                1つのデバイスで最大6種発信できるマルチアドバタイズ小型BLEビーコンを提供いたします。
                大容量電池(1000mAh)を搭載しているため、長期間利用が可能、
                また、iBeaconとEddystone両方に対応しており、防水(IP67)機能を持ち、加速度センサーも内包されています。
                Androidとiphoneの専用アプリを使って、各種設定を行えます。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_product_pct02.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>IoTゲートウェイ</h3>
              <p>
                BLEビーコンデバイスのデータを簡単にマネジメントし、バックエンドにデータ送信できるIoTゲートウェイを提供いたします。
                秒間300デバイスをスキャン可能で、HTTP/MQTT/TCPに対応し、AWS/Azure/ARM mBedのIoTクラウドプラットフォームに接続可能。
                ネットワーク接続不良時のデータロス防止にも対応いたします。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.iot_product_pct03.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>部品・モジュール</h3>
              <p>
                プロトタイプでよく使われるRaspberry pi、Arduino、NVIDIA Jetson
                に対応したモジュール、センサーや関連商品を取り扱っています。
                その他、法人向けではモジュール等のメーカ直接調達をお手伝いすることも可能です。
                メーカー交渉、輸入手続き、外貨支払い等の手間を当社が行うことにより、製品開発に専念して頂くことが可能になります。
              </p>
          </div>
        </div>
      </div>
    </section>




{/*     <section className="children">
      <h2 className="sr-only">children</h2>
      <figure>
        <Img
          fluid={data.children.childImageSharp.fluid}
          alt="プログラミング教育ソリューションを提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="solution">
      <div className="container">
        <h2 className="bar">
          <br />
          Solution <span>Information</span>
        </h2>
      </div>
    </section>
    
    <section>
      <div className="container">
        <h2 className="sr-only">RECENT POSTS</h2>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/jp/info/post/${node.slug}/`}>
                <figure>
                  <Img
                    fluid={node.eyecatch.fluid}
                    alt={node.eyecatch.description}
                    style={{ height: "100%" }}
                  />
                </figure>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </section> */}
  </Layout>
)

export const query = graphql`
  query {
    iot_solution_pct01: file(relativePath: { eq: "iot_solution_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_solution_pct02: file(relativePath: { eq: "iot_solution_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_solution_pct03: file(relativePath: { eq: "iot_solution_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_solution: file(relativePath: { eq: "iot_solution.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    iot_product_pct01: file(relativePath: { eq: "iot_product_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_product_pct02: file(relativePath: { eq: "iot_product_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_product_pct03: file(relativePath: { eq: "iot_product_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iot_product: file(relativePath: { eq: "iot_product.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    children: file(relativePath: { eq: "children.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pattern: file(relativePath: { eq: "pattern.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      filter: {category: { elemMatch: { categorySlug: { eq : "solutioninfo" }}}}
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

/* export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    product_pct01: file(relativePath: { eq: "product_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    product_pct02: file(relativePath: { eq: "product_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    product_pct03: file(relativePath: { eq: "product_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    product: file(relativePath: { eq: "product.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    children: file(relativePath: { eq: "children.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pattern: file(relativePath: { eq: "pattern.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allContentfulBlogPost(
      filter: { category: { elemMatch: { categorySlug: { eq : "productinfo" }}}}
      sort: { order: DESC, fields: publishDate }
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      } 
    }

  }
` */






export default Procuct
